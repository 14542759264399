import IFrameLogin from './Loaders/IFrameLogin'
import Config from './config'
import store from './store'
import utils from './utils'
import { generateCodeChallenge, generateCodeVerifier } from './pkce'
import Promise from 'promise-polyfill';
// import {OAuthPromiseClient} from '../proto/oauth_grpc_web_pb'
// import {TokenRequest} from '../proto/oauth_pb'
import axios from 'axios'
import { get } from 'lodash'
import Cookie from 'js-cookie'

const default_config = {
  'authorization': process.env.AUTHEN_ENDPOINT,
  'token': process.env.TOKEN_ENDPOINT,
  'agent': process.env.AGENT_ENDPOINT,
  'agentOrigin': process.env.AGENT_ORIGIN,
}

class SSO {
  constructor(config) {
    this.configure(config)
    this.loader = IFrameLogin
    this.store = store
    this.tokenKey = "token"
    this.isAgentReady = false;
    this.logoutCallback = () => {}
    this.setAgent()
  }

  setAgent() {
    this.agentIframe = document.createElement('iframe')
    this.agentIframe.setAttribute('id', 'agent')
    this.agentIframe.setAttribute('src', this.config.get('agent') + `?origin=${encodeURIComponent(window.location.origin)}`)
    this.agentIframe.setAttribute('style', 'display: none;')
    document.getElementsByTagName('body')[0].appendChild(this.agentIframe)
    let eventMethod = window.addEventListener ? "addEventListener" : "attachEvent"
    let loadEvent = eventMethod == "attachEvent" ? "onload" : "load"
    const aa = this;
    this.agentIframe[eventMethod](loadEvent, (e) => {
      aa.iframeLoaded()
    })

    //set agent listener
    const eventer = window[eventMethod]
    const messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message"
    eventer(messageEvent, (e) => {
      if(typeof e.data == 'string' && e.data !== "") {
        try {
          let command = JSON.parse(e.data)
          if('logout' === command.type) {
            this._logoutCallback()
          } else if('response' === command.type) {
            this.sendMessageResolve(command.data)
          }
        } catch (e) {
    
        }
      }
     
    })
  }

  _logoutCallback() {
    this.store.wipeTokens(this.tokenKey)
    if(typeof this.logoutCallback === 'function') {
      this.logoutCallback()
    }
  }

  logout() {
    Cookie.remove('interest_skip_timestamp')
    let that = this
    const scopesRequire = ''
    let command = JSON.stringify({
      method: 'logout',
      client_id: this.config.get('client_id'),
      params: {
        access_token: get(this.store.getToken(this.tokenKey, scopesRequire), 'access_token', undefined)
      }
    })
    let interval = setInterval(function() {
      if(that.isAgentReady) {
        clearInterval(interval)
        that.sendMessage(command)
        .then(data => {
          that._logoutCallback()
        })
      }
    }, 500)
    
  }

  isLogin() {

    return new Promise((resolve, reject) => {
      let command = JSON.stringify({
        method: 'isLogin',
        client_id: this.config.get('client_id')
      })
      this.sendMessage(command)
        .then(data => {
          if ('true' == data) {
            resolve(true)
          } else {
            resolve(false)
          }
        }).catch(err => {
          resolve(false)
        })
    })
  }

  sendMessage(command) {
    return new Promise((resolve, reject) => {
      this.agentIframe.contentWindow.postMessage(command, '*')
      this.sendMessageResolve = resolve
      setTimeout(function () {
        reject(new Error("time out"))
      }, 5000);
    })


  }

  iframeLoaded() {
    this.isAgentReady = true;
  }

  configure(config) {
    this.config = new Config(default_config, config)
  }

  callback(data) {
    let response = null
    if (typeof data === 'object') {
      response = data
    }

    if (response.hasOwnProperty("code")) {
      return this.processAuthorizationCodeResponse(response)
    }

  }

  callbackAutoLogin(optionsToken) {
    return this.processAuthorizationCodeResponseAutoLogin(optionsToken)
  }

  getToken() {
    return new Promise((resolve, reject) => {
      let scopesRequire = ""
      let token = this.store.getToken(this.tokenKey, scopesRequire)
      if (token) {
        return resolve(token)
      } else {
        let that = this
        let interval = setInterval(function() {
          if(that.isAgentReady) {
            clearInterval(interval)
            that.isLogin().then(islogin => {
              if (islogin) {
                return resolve(that.autholize(false));
              }
              else {
                return resolve(null)
              }
            })
            .catch(err => {
              reject(err)
            })
          }
        }, 500)
       
      }
    })
  }

  getNewToken() {
    return new Promise((resolve, reject) => {
      let that = this
      let interval = setInterval(function() {
        if(that.isAgentReady) {
          clearInterval(interval)
          that.isLogin().then(islogin => {
            if (islogin) {
              return resolve(that.autholize(false));
            }
            else {
              return resolve(null)
            }
          })
          .catch(err => {
            reject(err)
          })
        }
      }, 1000)
    })
  }

  login() {
    return new Promise((resolve, reject) => {
      let scopesRequire = ""
      let token = this.store.getToken(this.tokenKey, scopesRequire)
      if (token) {
        return resolve(token)
      } else {
        return resolve(this.autholize(true))
      }
    })
  }

  interest(frontendPage = undefined) {
    return new Promise((resolve, reject) => {
      resolve(this.autholizeWithToken(true, frontendPage))
    })
  }

  autholizeWithToken(display, frontendPage) {
    var request, authurl, scopes
    return Promise.resolve().then(() => {
      let locale = this.config.get('lang', '', false)
      let country = this.config.get('country', '', false)
      let authorization = this.config.get('authorization', '', true)
      let client_id = this.config.get('client_id', '', true)
      let redirect_uri = this.config.get('redirect_uri', '', true)
      let code_verifier = generateCodeVerifier()
      let code_challenge = generateCodeChallenge(code_verifier)
      scopes = ''
      request = {}
      request.state = utils.uuid()
      request.redirect_uri = redirect_uri
      request.client_id = client_id
      request.scope = utils.scopeList(scopes)
      request.code_challenge = code_challenge
      request.code_challenge_method = 'S256'
      request.frontendPage = frontendPage
      if(!display) {
        request.prompt = "none"
      }

      authurl = utils.encodeURL(authorization, request)

      const that = this
      const cookieCommand = JSON.stringify({
        method: 'setCookies',
        params: { locale, country }
      })
      const interval = setInterval(function() {
        if(that.isAgentReady) {
          clearInterval(interval)
          that.sendMessage(cookieCommand)
        }
      }, 500)

      this.store.saveState(request.state, request)
      this.store.saveCodeVerifier(code_verifier)

      return this.gotoAuthorize(authurl, this.loader, display, locale)
    })
  }

  autholize(display) {
    var request,
      authurl,
      scopes
    return Promise.resolve().then(() => {
      let locale = this.config.get('lang', '', false)
      let country = this.config.get('country', '', false)
      let authorization = this.config.get('authorization', '', true)
      let client_id = this.config.get('client_id', '', true)
      let redirect_uri = this.config.get('redirect_uri', '', true)
      let code_verifier = generateCodeVerifier()
      let code_challenge = generateCodeChallenge(code_verifier)
      scopes = ''
      request = {}
      request.state = utils.uuid()
      request.redirect_uri = redirect_uri
      request.client_id = client_id
      request.scope = utils.scopeList(scopes)
      request.code_challenge = code_challenge
      request.code_challenge_method = 'S256'
      if(!display) {
        request.prompt = "none"
      }

      authurl = utils.encodeURL(authorization, request)

      const that = this
      const cookieCommand = JSON.stringify({
        method: 'setCookies',
        params: { locale, country }
      })
      const interval = setInterval(function() {
        if(that.isAgentReady) {
          clearInterval(interval)
          that.sendMessage(cookieCommand)
        }
      }, 500)


      this.store.saveState(request.state, request)
      this.store.saveCodeVerifier(code_verifier)

      return this.gotoAuthorize(authurl, this.loader, display, locale)
        .then((response) => {
          if (response !== true) {
            return this.callback(response)
          }
        }).catch(err => {
          this.store.getState(request.state)
          throw err;
        })
    });
  }

  autoLogin(optionsToken) {
    return new Promise((resolve, reject) => {
      let scopesRequire = ""
      let token = this.store.getToken(this.tokenKey, scopesRequire)
      if (token) {
        this.saveTokenSso(token)
        return resolve(token)
      } else {
        return resolve(this.autholizeForAutoLogin(optionsToken))
      }
    })
  }

  autholizeForAutoLogin(optionsToken) {
    let display = false
    var request,
      authurl,
      scopes
    return Promise.resolve().then(() => {

      let authorization = this.config.get('authorization', '', true)
      let client_id = this.config.get('client_id', '', true)
      let redirect_uri = this.config.get('redirect_uri', '', true)
      let code_verifier = generateCodeVerifier()
      let code_challenge = generateCodeChallenge(code_verifier)

      scopes = ''
      request = {}
      request.state = utils.uuid()
      request.redirect_uri = redirect_uri
      request.client_id = client_id
      request.scope = utils.scopeList(scopes)
      request.code_challenge = code_challenge
      request.code_challenge_method = 'S256'
      if(!display) {
        request.prompt = "none"
      }

      authurl = utils.encodeURL(authorization, request)

      this.store.saveState(request.state, request)
      this.store.saveCodeVerifier(code_verifier)

      return this.callbackAutoLogin(optionsToken)

    });
  }

  saveTokenSso(token) {
    const that = this
    const cookieCommand = JSON.stringify({
      method: 'saveToken',
      params: { token }
    })

    const interval = setInterval(function() {
      if(that.isAgentReady) {
        clearInterval(interval)
        that.sendMessage(cookieCommand)
      }
    }, 500)
  }

  gotoAuthorize(url, Loader, display, locale) {
    const d = display
    return new Promise(function (resolve, reject) {
      if (Loader !== null && typeof Loader === 'function') {
        const loader = new Loader(url, d, locale)

        resolve(loader.execute())
      } else {
        reject(new Error('Cannot redirect to authorization'))
      }
    })
  }


  processAuthorizationCodeResponse(object) {

    // this.emit('authorizationCode', object)

    let client_id = this.config.get('client_id', '', true)
    let code_verifier = this.store.getCodeVerifier()
    let state
    if (object.state) {
      state = this.store.getState(object.state)
      if (state === null) {
        throw new Error("Could not find retrieve state object.")
      }
    } else {
      throw new Error("Could not find state paramter from callback.")
    }


    if (!this.config.has('token')) {
      throw new Error("token url is required")
      return
    }

    let tokenRequest = {
      'grant_type': 'authorization_code',
      'code': object.code,
      'client_id': client_id,
      'code_verifier': code_verifier
    }

    return axios.post(this.config.get('token'), tokenRequest)
      .then(tokenResponse => {
        return this.processReceivedToken(tokenResponse.data, state)
      })

  }

  processAuthorizationCodeResponseAutoLogin(optionsToken) {

    let client_id = this.config.get('client_id', '', true)
    let code_verifier = this.store.getCodeVerifier()

    if (!this.config.has('token')) {
      throw new Error("token url is required")
      return
    }

    let tokenRequest = {
      'grant_type': 'user_id',
      'client_id': client_id,
      'client_secret': code_verifier,
      'username': optionsToken.user_id
    }

    return axios.post(this.config.get('token'), tokenRequest)
      .then(tokenResponse => {
        const token = get(tokenResponse, 'data', undefined)
        if (token) {
          this.saveTokenSso(token)
        }
        return this.processReceivedTokenAutoLogin(tokenResponse.data)
      })

  }

  processReceivedTokenAutoLogin(atoken) {
    let now = utils.epoch()
    atoken.received = now
    if (atoken.expires_in) {
      if(typeof atoken.expires_in == "string") {
        atoken.expires_in = parseInt(atoken.expires_in)
      }
      atoken.expires = now + atoken.expires_in
      atoken.expires_in = atoken.expires_in
    }

		/*
		 * Handle scopes for this token
		 */
    if (atoken.scope) {
      atoken.scopes = atoken.scope.split(" ")
      delete atoken.scope
    } else {
      atoken.scopes = []
    }

    utils.log("processTokenResponse completed ", atoken, "")

    this.store.saveToken(this.tokenKey, atoken)

    window.location.hash = ''
    return atoken
  }

  processReceivedToken(atoken, state) {
    let now = utils.epoch()
    atoken.received = now
    if (atoken.expires_in) {
      if(typeof atoken.expires_in == "string") {
        atoken.expires_in = parseInt(atoken.expires_in)
      }
      atoken.expires = now + atoken.expires_in
      atoken.expires_in = atoken.expires_in
    }

		/*
		 * Handle scopes for this token
		 */
    if (atoken.scope) {
      atoken.scopes = atoken.scope.split(" ")
      delete atoken.scope
    } else if (state.scopes) {
      atoken.scopes = state.scopes
    } else {
      atoken.scopes = []
    }

    utils.log("processTokenResponse completed ", atoken, "")

    this.store.saveToken(this.tokenKey, atoken)

    if (state.restoreHash) {
      window.location.hash = state.restoreHash
    } else {
      window.location.hash = ''
    }
    return atoken
  }

  userInfo() {
    if (!this.config.has('profile')) {
      throw new Error("profile url is required")
      return
    }

    let requestData = {
    }
    var tokenInfo = this.store.getToken(this.tokenKey, "")
    var token, tokenType
    if (tokenInfo != null) {
      token = tokenInfo.access_token
      tokenType = tokenInfo.token_type
    }

    let headers = {
      headers: {
          'Authorization': `${tokenType} ${token}`,
      }
    }

    return axios.post(this.config.get('profile'), requestData, headers)
  }

  updateProfile(data) {
    if (!this.config.has('updateProfile')) {
      throw new Error("updateProfile url is required")
      return
    }

    var tokenInfo = this.store.getToken(this.tokenKey, "")
    var token, tokenType
    if (tokenInfo != null) {
      token = tokenInfo.access_token
      tokenType = tokenInfo.token_type
    }

    let headers = {
      headers: {
          'Authorization': `${tokenType} ${token}`,
      }
    }

    return axios.post(this.config.get('updateProfile'), data, headers)
  }

  deleteAccount() {
    if (!this.config.has('deleteAccount')) {
      throw new Error("deleteAccount url is required")
      return
    }

    var tokenInfo = this.store.getToken(this.tokenKey, "")
    var token, tokenType
    if (tokenInfo != null) {
      token = tokenInfo.access_token
      tokenType = tokenInfo.token_type
    }

    let headers = {
      headers: {
          'Authorization': `${tokenType} ${token}`,
      }
    }

    return axios.post(this.config.get('deleteAccount'), {}, headers)
  }

  uploadProfilePicture(imgData) {
    if (!this.config.has('updateProfilePicture')) {
      throw new Error("updateProfilePicture url is required")
      return
    }

    var tokenInfo = this.store.getToken(this.tokenKey, "")
    var token, tokenType
    if (tokenInfo != null) {
      token = tokenInfo.access_token
      tokenType = tokenInfo.token_type
    }

    let headers = {
      headers: {
          'Authorization': `${tokenType} ${token}`,
      }
    }

    let reqData = {
      image_data: imgData
    }

    return axios.post(this.config.get('updateProfilePicture'), reqData, headers)
  }
}

export { SSO }
window.SSO = SSO;