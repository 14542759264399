import BasicLoader from './BasicLoader'
import utils from '../utils'
import Promise from 'promise-polyfill';
import ssoStyles from '../../styles/iframe-sso.css'
import Cookies from 'js-cookie'
import moment from 'moment'
import get from 'lodash/get'
import omit from 'lodash/omit'

export default class IFrameLogin extends BasicLoader {
  constructor(url, display, locale = 'th') {
    super(url)
    this.display = display || false
    this.timeout = 1000 * 60 * 20
    this.backgroundTimeout = 1000 * 10
    this.callback = null
    this.isCompleted = false
    this.id = 'sso_iframe_' + utils.uuid()
    this.locale = locale

    // Create IE + others compatible event handler
    // var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent"
    // var eventer = window[eventMethod]
    // var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message"

    this.iframe = document.createElement('iframe')
    this.iframe.setAttribute('id', this.id)
    this.iframe.setAttribute('src', url)
    this.iframe.setAttribute('name', 'iframe-login')
    // this.iframe.setAttribute('scrolling', 'no')
    if (!this.display) {
      this.iframe.setAttribute('style', 'display: none')
    } else {
      let modalDiv = document.createElement('div')
      modalDiv.setAttribute('class', 'w3-modal')

      let modalDivContent = document.createElement('div')
      modalDivContent.setAttribute('class', 'w3-modal-content')
      let modalDivContainer = document.createElement('div')
      modalDivContainer.setAttribute('class', 'w3-container')

      let modalCloseBtnSpan = document.createElement('span')
      modalCloseBtnSpan.setAttribute('class', 'w3-button w3-display-topright')
      modalCloseBtnSpan.innerHTML = "&times;"
      modalCloseBtnSpan.id = "close-login-btn"
      modalCloseBtnSpan.onclick = () => { this.close() }
      this.iframe.scroll = 'auto'

      modalDivContainer.appendChild(modalCloseBtnSpan)
      modalDivContainer.appendChild(this.iframe)
      modalDivContent.appendChild(modalDivContainer)
      modalDiv.appendChild(modalDivContent)

      const body = document.getElementsByTagName('body')
      body[0].style.overflow = 'hidden'
      this.modal = modalDiv
    }

    this.iframe.addEventListener('load', (e) => {
      let obj = null
      try {
        if (this.iframe.contentWindow.location.href.indexOf(window.location.origin) != -1) {
          if (this.iframe.contentWindow.location.hash) {
            let encodedHash = this.iframe.contentWindow.location.hash.substring(1)
            obj = utils.parseQueryString(encodedHash)
          } else if (this.iframe.contentWindow.location.search) {
            let encodedHash = this.iframe.contentWindow.location.search.substring(1)
            obj = utils.parseQueryString(encodedHash)
          }
          if (typeof obj != "undefined" && obj !== null) {
            this._completed(obj)
          } else {
            this._failed(new Error("Failed to obtain response value from iframe"))
          }
        }
      } catch (err) {
        console.log('err', err);
      }
    })

    window.addEventListener('message', (e) => {
      // message that was passed from iframe page
      let message = e.data || {}
      const modalDiv = document.getElementsByClassName('w3-modal')
      const modalDivContent = document.getElementsByClassName('w3-modal-content')
      const modalDivContainer = document.getElementsByClassName('w3-container')
      const closeBtn = document.getElementById('close-login-btn')

      if (message.type === 'redirect') {
        window.location.href = message.value
      } else if (message.type === 'restart-sso') {
        this.close()
      } else if (message.type === 'fullscreen') {

        modalDiv[0].style.padding = '0px'

        modalDivContent[0].style.maxWidth = 'unset'
        modalDivContent[0].style.width = '100%'
        modalDivContent[0].style.minWidth = '100%'
        modalDivContent[0].style.margin = '0px'

        modalDivContainer[0].style.position = 'relative'

        closeBtn.classList.add('w3-display-topright-fullscreen')

      } else if (message.type === 'normal-screen') {

        modalDiv[0].style.removeProperty('padding')

        modalDivContent[0].style.removeProperty('max-width')
        modalDivContent[0].style.removeProperty('width')
        modalDivContent[0].style.removeProperty('min-width')
        modalDivContent[0].style.removeProperty('margin')

        modalDivContainer[0].style.removeProperty('position')

        closeBtn.classList.remove('w3-display-topright-fullscreen')

      } else if (message.type === 'hide-close-btn') {
        closeBtn.style.display = 'none'
      } else if (message.type === 'show-close-btn') {
        closeBtn.style.removeProperty('display')
      } else if (message.type === 'point_interest_campaign') {
        this.setCookies(message.type, message.value)
      }
    }, false)
  }

  setScrollToBody() {
    const body = document.getElementsByTagName('body')
    body[0].style.overflow = 'visible'
  }

  close() {
    this._failed(new Error("user close"))
    this.setScrollToBody()
  }

  setCookies(name, value) {
    Cookies.remove('interest_skip_timestamp')

    let data = Cookies.get(name)
    if (data) {
      data = JSON.parse(data)
    }
    const frontendPage = get(value, 'frontendPage', undefined)
    value = omit(value, ['frontendPage'])
    const keys = Object.keys(value)
    let newData = data || {}
    keys.forEach(v => {
      if (data && data[v]) {
        newData[v] = value[v]
      } else {
        newData = Object.assign(newData, value)
      }

      if ((value[v] || value[v] == false) && v !== 'profile' && frontendPage !== 'interest-mission-point') {
        const interestSkipTimestamp = moment().add(24, 'hours').format('YYYY-MM-DD HH:mm:ss')
        Cookies.set('interest_skip_timestamp', interestSkipTimestamp, { expires: 1 }) // expires (unit: day(s))
      }
    })
    newData = JSON.stringify(newData)
    Cookies.set(name, newData)
  }

  execute() {
    return new Promise((resolve, reject) => {
      this.callback = resolve
      this.errorCallback = reject
      if (this.modal) {
        var styleSheet = document.createElement("style")
        styleSheet.type = "text/css"
        styleSheet.innerText = ssoStyles
        document.getElementsByTagName('body')[0].appendChild(this.modal)
        document.getElementsByTagName('body')[0].appendChild(styleSheet)
        this.modal.style.display = 'block'
        setTimeout(() => {
          this._failed(new Error("Loading iframe timed out"))
        }, this.timeout)
      } else {
        document.getElementsByTagName('body')[0].appendChild(this.iframe)
        setTimeout(() => {
          this._failed(new Error("Loading iframe timed out"))
        }, this.backgroundTimeout)
      }
    })
  }

  _cleanup() {
    if (this.modal) {
      this.modal.parentNode.removeChild(this.modal)
    } else {
      let element = document.getElementById(this.id)
      this.iframe.removeEventListener('load', function () { })
      element.parentNode.removeChild(element)
    }
    this.setScrollToBody()
  }

  _failed(err) {
    if (!this.isCompleted) {
      if (this.errorCallback && typeof this.errorCallback === 'function') {
        this.errorCallback(err)
      }
      this.isCompleted = true
      this._cleanup()
    }
  }

  _completed(response) {
    if (!this.isCompleted) {
      if (this.callback && typeof this.callback === 'function') {
        this.callback(response)
      }
      this.isCompleted = true
      this._cleanup()
    }
  }
}