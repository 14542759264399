import utils from './utils'

class Store {

	constructor() {
	}

  saveCodeVerifier(codeVerifier) {
    localStorage.setItem("cv", codeVerifier)
  }

  getCodeVerifier() {
		let cv =  localStorage.getItem("cv")
		localStorage.removeItem("cv")
		return cv
	}
	

	saveState(state, obj) {
		localStorage.setItem("state-" + state, JSON.stringify(obj))
	}

	getState(state) {
		var obj = JSON.parse(localStorage.getItem("state-" + state))
		localStorage.removeItem("state-" + state)
		return obj
	}



	hasScope(token, scope) {
		var i
		if (!token.scopes) return false
		for(i = 0; i < token.scopes.length; i++) {
			if (token.scopes[i] === scope) return true
		}
		return false
	}

	filterTokens(tokens, scopes) {
		var i, j,
			result = [],
			now = utils.epoch(),
			usethis

		if (!scopes) scopes = []

		for(i = 0; i < tokens.length; i++) {
			usethis = true

			if (tokens[i].expires && tokens[i].expires < (now+1)) usethis = false

			for(j = 0; j < scopes.length; j++) {
				if (!this.hasScope(tokens[i], scopes[j])) usethis = false
			}

			if (usethis) result.push(tokens[i])
		}
		return result
	}



	saveTokens(provider, tokens) {
		localStorage.setItem("tokens-" + provider, JSON.stringify(tokens))
	}

	getTokens(provider) {
		var tokens = JSON.parse(localStorage.getItem("tokens-" + provider))
		if (!tokens) tokens = []

		return tokens
	}

	wipeTokens(provider) {
		localStorage.removeItem("tokens-" + provider)
	}

	saveToken(provider, token) {
		var tokens = this.getTokens(provider)
		tokens = this.filterTokens(tokens)
		tokens.push(token)
		this.saveTokens(provider, tokens)
	}

	getToken(provider, scopes) {
		var tokens = this.getTokens(provider)
		tokens = this.filterTokens(tokens, scopes)
		if (tokens.length < 1) return null
		return tokens[0]
	};

}

var s = new Store()

export default s