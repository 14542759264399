import {SHA256,enc} from 'crypto-js'


const base64URL = (string) => {
  return string.toString(enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

const generateRandomString = (length) => {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}


export const generateCodeChallenge = (code_verifier) => {
  return base64URL(SHA256(code_verifier))
}

export const generateCodeVerifier = () => {
  return generateRandomString(64)
}